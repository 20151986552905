import React from 'react';
import PropTypes from 'prop-types';

const Description = ({ children }) => (
  <div className="personalDataSheet__description">
    { children }
  </div>
);

Description.propTypes = {
  children: PropTypes.any,
};

Description.defaultProps = {
  children: '',
};

export default Description;
