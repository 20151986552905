import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

import PersonalStage, { PersonalStageShape } from './PersonalStage';

const PersonalDataSheet = ({ stages }) => (
  <div className="personalDataSheet">
    { stages.map((stage, i) => <PersonalStage key={stage.title} {...stage} isFirst={i === 0} isLast={i === (stages.length - 1)} />)}
  </div>
);

PersonalDataSheet.propTypes = {
  stages: PropTypes.arrayOf(PersonalStageShape).isRequired,
};

export default PersonalDataSheet;
