import Page from '../components/Page';
import PersonalDataSheet from '../components/PersonalDataSheet';
import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby'
import readNodes from '../utils/readNodes';
import styled from 'styled-components';

const Section = styled.section`
  margin-bottom: 3rem;
`;

const IndexPage = ({
  data: {
    Berufserfahrung,
    Ausbildung,
    footer: {
      htmlAst: footerAst,
    },
  },
}) => (
  <Page footerAst={footerAst}>
    <h5>Profil</h5>
    <Section>
      <h2>Berufserfahrung</h2>
      <PersonalDataSheet stages={readNodes(Berufserfahrung)} />
    </Section>
    <Section>
      <h2>Ausbildung</h2>
      <PersonalDataSheet stages={readNodes(Ausbildung)} />
    </Section>
  </Page>
);

IndexPage.propTypes = {
  data: PropTypes.shape({
    footer: PropTypes.shape({
      htmlAst: PropTypes.object.isRequired,
    }).isRequired,
  }).isRequired,
};

export const pageQuery = graphql`
  query profil {
    Berufserfahrung: allBerufserfahrungYaml {
      edges {
        node {
          time
          title
          location
          description
        }
      }
    }

    Ausbildung: allAusbildungYaml {
      edges {
        node {
          time
          title
          location
        }
      }
    }

    footer: markdownRemark(frontmatter: { type: { eq: "footer" }}) {
      htmlAst
    }
  }
`;

export default IndexPage;
