import React from 'react';
import PropTypes from 'prop-types';

const Title = ({ children }) => (
  <div className="personalDataSheet__title">
    { children }
  </div>
);

Title.propTypes = {
  children: PropTypes.any,
};

Title.defaultProps = {
  children: '',
};

export default Title;
