import React from 'react';
import PropTypes from 'prop-types';

const TimePeriod = ({ children }) => (
  <div className="personalDataSheet__timePeriod">
    { children }
  </div>
);

TimePeriod.propTypes = {
  children: PropTypes.any,
};

TimePeriod.defaultProps = {
  children: '',
};

export default TimePeriod;
