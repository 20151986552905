import React from 'react';
import PropTypes from 'prop-types';

const StageInfoLayout = ({ children }) => (
  <div className="personalDataSheet__stageInfoLayout">
    { children }
  </div>
);

StageInfoLayout.propTypes = {
  children: PropTypes.any,
};

StageInfoLayout.defaultProps = {
  children: '',
};

export default StageInfoLayout;
