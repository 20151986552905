import React from 'react';
import PropTypes from 'prop-types';

const Location = ({ children }) => (
  <div className="personalDataSheet__location">
    { children }
  </div>
);

Location.propTypes = {
  children: PropTypes.any,
};

Location.defaultProps = {
  children: '',
};

export default Location;
