import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import TimePeriod from './TimePeriod';
import StageInfoLayout from './StageInfoLayout';
import Title from './Title';
import Location from './Location';
import Description from './Description';

const CSS_CLASS = 'personalDataSheet__personalStage';

const PersonalStage = ({
  time,
  title,
  location,
  description,
  isFirst,
  isLast,
}) => (
  <div className={cx(CSS_CLASS, { [`${CSS_CLASS}--first`]: isFirst, [`${CSS_CLASS}--last`]: isLast })}>
    <TimePeriod>{ time }</TimePeriod>
    <StageInfoLayout>
      <Title>{ title }</Title>
      { location && <Location>{ location }</Location> }
      { description && <Description>{ description }</Description> }
    </StageInfoLayout>
  </div>
);

PersonalStage.propTypes = {
  time: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  location: PropTypes.string,
  description: PropTypes.string,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
};

PersonalStage.defaultProps = {
  location: undefined,
  description: undefined,
  isFirst: false,
  isLast: false,
};

export default PersonalStage;
